import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const index = () => import('../views/layout');

// 不经过权限的路由
export const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/login/404'),
  },

  {
    path: '/ValuationItemEdit/:id',
    name: 'ValuationItemEdit',
    component: () => import('../views/TwoRecycling/ValuationItem/ValuationItemEdit.vue'),
    meta: { title: '估值问题设置', code: '14030101' },
  },
  {
    path: '/ProHistoryEdit/:id',
    name: 'ProHistoryEdit',
    component: () => import('../views/TwoRecycling/RecoveryPro/ProHistory/ProHistoryEdit.vue'),
    meta: { title: '添加历史估值', code: '14030401' },
  },

  /**
   * @description 本地调试页面时不走权限，直接写静态路由方便调试和写页面 start
   * @description 对接权限时，将此处路由移动到下方的 权限路由列表中...
   *
   * */
  // {
  //   path: '/SystemManage',
  //   name: 'SystemManage',
  //   component: index,
  //   meta: { title: '系统管理', code: '1402', dataKey: '1', iconUrl: 'iconfont icon-xitongshezhi' },
  //   redirect: 'RoleManage',
  //   children: [
  //     {
  //       path: 'RoleManage',
  //       name: 'RoleManage',
  //       component: () => import('../views/SystemManage/RoleManage.vue'),
  //       meta: { title: '角色管理', code: '140201', dataKey: '1-0' },
  //     },
  //     {
  //       path: 'UserManage',
  //       name: 'UserManage',
  //       component: () => import('../views/SystemManage/UserManage.vue'),
  //       meta: { title: '用户管理', code: '140202', dataKey: '1-1' },
  //     },
  //     {
  //       path: 'AuthManage',
  //       name: 'AuthManage',
  //       component: () => import('../views/SystemManage/AuthManage.vue'),
  //       meta: { title: '权限管理', code: '140203', dataKey: '1-2' },
  //     },
  //     {
  //       path: 'CompanyAccount',
  //       name: 'CompanyAccount',
  //       component: () => import('../views/SystemManage/CompanyAccount.vue'),
  //       meta: { title: '公司收付款账户维护', code: '140204', dataKey: '1-3' },
  //     },
  //   ],
  // },
  // {
  //   path: '/TwoRecycling',
  //   name: 'TwoRecycling',
  //   component: index,
  //   meta: { title: '二手回收', code: '1403', dataKey: '2', iconUrl: 'iconfont icon-huishou' },
  //   redirect: 'ValuationItem',
  //   children: [
  //     {
  //       path: 'ValuationItem',
  //       name: 'ValuationItem',
  //       component: () => import('../views/TwoRecycling/ValuationItem.vue'),
  //       meta: { title: '估值项目配置', code: '140301', dataKey: '2-0' },
  //     },
  //     {
  //       path: 'ProManage',
  //       name: 'ProManage',
  //       component: () => import('../views/TwoRecycling/RecoveryPro/ProManage.vue'),
  //       meta: { title: '产品列表', code: '140302', dataKey: '2-1' },
  //     },
  //     {
  //       path: 'ProClassify',
  //       name: 'ProClassify',
  //       component: () => import('../views/TwoRecycling/RecoveryPro/ProClassify.vue'),
  //       meta: { title: '产品分类', code: '140303', dataKey: '2-2' },
  //     },
  //     {
  //       path: 'ProHistory',
  //       name: 'ProHistory',
  //       component: () => import('../views/TwoRecycling/RecoveryPro/ProHistory.vue'),
  //       meta: { title: '产品估值历史', code: '140304', dataKey: '2-3' },
  //     },
  //     {
  //       path: 'ValuationAttr',
  //       name: 'ValuationAttr',
  //       component: () => import('../views/TwoRecycling/ValuationAttr.vue'),
  //       meta: { title: '估值参数配置', code: '140301', dataKey: '2-4' },
  //     },
  //     {
  //       path: 'RenewalFee',
  //       name: 'RenewalFee',
  //       component: () => import('../views/TwoRecycling/RenewalFee.vue'),
  //       meta: { title: '续期仓管费用配置', code: '140305', dataKey: '2-5' },
  //     },
  //   ],
  // },

  // {
  //   path: '/OrderManagement',
  //   name: 'OrderManagement',
  //   meta: {
  //     title: '订单管理',
  //     dataKey: '3',
  //   },
  //   component: index,
  //   redirect: '/OrderManagement/OrderList',
  //   children: [
  //     {
  //       path: 'OrderList',
  //       name: 'OrderList',
  //       component: () => import('../views/OrderManagement/OrderList/Index.vue'),
  //       meta: {
  //         title: '订单列表',
  //         dataKey: '3-0',
  //       },
  //     },
  //     {
  //       path: 'TestValuation',
  //       name: 'TestValuation',
  //       component: () => import('../views/OrderManagement/TestValuation/Index.vue'),
  //       meta: {
  //         title: '订单-检测估值',
  //         dataKey: '3-1',
  //       },
  //     },
  //     {
  //       path: 'ContractDelivery',
  //       name: 'ContractDelivery',
  //       component: () => import('../views/OrderManagement/ContractDelivery/Index.vue'),
  //       meta: {
  //         title: '订单-签约交付',
  //         dataKey: '3-2',
  //       },
  //     },
  //     {
  //       path: 'RedemptionAtMaturity',
  //       name: 'RedemptionAtMaturity',
  //       component: () => import('../views/OrderManagement/RedemptionAtMaturity/Index.vue'),
  //       meta: {
  //         title: '订单-期满赎回',
  //         dataKey: '3-3',
  //       },
  //     },
  //     {
  //       path: 'Renewal',
  //       name: 'Renewal',
  //       component: () => import('../views/OrderManagement/Renewal/Index.vue'),
  //       meta: {
  //         title: '订单-续期',
  //         dataKey: '3-4',
  //       },
  //     },
  //     {
  //       path: 'GiveUpRealRight',
  //       name: 'GiveUpRealRight',
  //       component: () => import('../views/OrderManagement/GiveUpRealRight/Index.vue'),
  //       meta: {
  //         title: '订单-放弃物权',
  //         dataKey: '3-5',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/StorageManagement',
  //   name: 'StorageManagement',
  //   meta: {
  //     title: '库存管理',
  //     dataKey: '4',
  //   },
  //   component: index,
  //   redirect: '/StorageManagement/StorageRecord',
  //   children: [
  //     {
  //       path: 'StorageRecord',
  //       name: 'StorageRecord',
  //       component: () => import('../views/Storage/StorageRecord.vue'),
  //       meta: {
  //         title: '库存货品列表管理',
  //         dataKey: '4-0',
  //       },
  //     },
  //     {
  //       path: 'WarehouseList',
  //       name: 'WarehouseList',
  //       component: () => import('../views/Storage/WarehouseList.vue'),
  //       meta: {
  //         title: '货仓列表',
  //         dataKey: '4-1',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/UserManagement',
  //   name: 'UserManagement',
  //   meta: {
  //     title: '用户管理',
  //     dataKey: '5',
  //   },
  //   component: index,
  //   redirect: '/UserManagement/UserList',
  //   children: [
  //     {
  //       path: 'UserList',
  //       name: 'UserList',
  //       component: () => import('../views/UserManagement/UserList.vue'),
  //       meta: {
  //         title: '用户列表',
  //         dataKey: '5-0',
  //       },
  //     },
  //     {
  //       path: 'UserApplList',
  //       name: 'UserApplList',
  //       component: () => import('../views/UserManagement/UserApplList.vue'),
  //       meta: {
  //         title: '用户申请列表',
  //         dataKey: '5-1',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/Developing',
  //   name: 'Developing',
  //   meta: {
  //     title: 'Developing',
  //     dataKey: '6',
  //   },
  //   component: index,
  //   redirect: '/Developing/Index',
  //   children: [
  //     {
  //       path: 'Index',
  //       name: 'Index',
  //       component: () => import('../views/Developing/Index.vue'),
  //       meta: {
  //         title: '开发中',
  //         dataKey: '6-0',
  //       },
  //     },
  //   ],
  // },
  // 本地调试页面时不走权限，直接写静态路由方便调试和写页面 end
];

export const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });
const router = createRouter();

export default router;

// 需要权限处理的路由
export const authRoutes = [
  {
    path: '/homepage',
    name: 'homepage',
    component: index,
    meta: { title: '首页', code: '1401', dataKey: '0' },
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { title: '首页', dataKey: '0-0' },
      },
    ],
  },
  {
    path: '/SystemManage',
    name: 'SystemManage',
    component: index,
    meta: { title: '系统管理', code: '1402', dataKey: '1', iconUrl: 'iconfont icon-xitongshezhi' },
    redirect: 'RoleManage',
    children: [
      {
        path: 'RoleManage',
        name: 'RoleManage',
        component: () => import('../views/SystemManage/RoleManage.vue'),
        meta: { title: '角色管理', code: '140201', dataKey: '1-0' },
      },
      {
        path: 'UserManage',
        name: 'UserManage',
        component: () => import('../views/SystemManage/UserManage.vue'),
        meta: { title: '系统用户管理', code: '140202', dataKey: '1-1' },
      },
      {
        path: 'AuthManage',
        name: 'AuthManage',
        component: () => import('../views/SystemManage/AuthManage.vue'),
        meta: { title: '权限管理', code: '140203', dataKey: '1-2' },
      },
      {
        path: 'CompanyAccount',
        name: 'CompanyAccount',
        component: () => import('../views/SystemManage/CompanyAccount.vue'),
        meta: { title: '公司收付款账号维护', code: '140204', dataKey: '1-3' },
      },
      {
        path: 'PaymentAccountQuery',
        name: 'PaymentAccountQuery',
        component: () => import('../views/SystemManage/PaymentAccountQuery.vue'),
        meta: { title: '公司收付款台账查询', code: '140205', dataKey: '1-4' },
      },
      {
        path: 'SystemConfig',
        name: 'SystemConfig',
        component: () => import('../views/SystemManage/SystemConfig.vue'),
        meta: { title: '系统配置', code: '140206', dataKey: '1-5' },
      },
    ],
  },
  {
    path: '/TwoRecycling',
    name: 'TwoRecycling',
    component: index,
    meta: { title: '二手回收', code: '1403', dataKey: '2', iconUrl: 'iconfont icon-huishou' },
    redirect: 'ValuationItem',
    children: [
      {
        path: 'ValuationItem',
        name: 'ValuationItem',
        component: () => import('../views/TwoRecycling/ValuationItem.vue'),
        meta: { title: '估值项目配置', code: '140301', dataKey: '2-0' },
      },
      {
        path: 'ProManage',
        name: 'ProManage',
        component: () => import('../views/TwoRecycling/RecoveryPro/ProManage.vue'),
        meta: { title: '产品列表', code: '140302', dataKey: '2-1' },
      },
      {
        path: 'ProClassify',
        name: 'ProClassify',
        component: () => import('../views/TwoRecycling/RecoveryPro/ProClassify.vue'),
        meta: { title: '产品分类', code: '140303', dataKey: '2-2' },
      },
      {
        path: 'ProHistory',
        name: 'ProHistory',
        component: () => import('../views/TwoRecycling/RecoveryPro/ProHistory.vue'),
        meta: { title: '产品维护历史', code: '140304', dataKey: '2-3' },
      },
      {
        path: 'ValuationAttr',
        name: 'ValuationAttr',
        component: () => import('../views/TwoRecycling/ValuationAttr.vue'),
        meta: { title: '估值参数配置', code: '140301', dataKey: '2-4' },
      },
      {
        path: 'RenewalFee',
        name: 'RenewalFee',
        component: () => import('../views/TwoRecycling/RenewalFee.vue'),
        meta: { title: '续期仓管费用配置', code: '140305', dataKey: '2-5' },
      },
    ],
  },

  {
    path: '/OrderManagement',
    name: 'OrderManagement',
    meta: {
      title: '订单管理',
      dataKey: '3',
      iconUrl: 'iconfont icon-reportManage',
    },
    component: index,
    redirect: '/OrderManagement/OrderList',
    children: [
      {
        path: 'OrderList',
        name: 'OrderList',
        component: () => import('../views/OrderManagement/OrderList/Index.vue'),
        meta: {
          title: '订单列表',
          dataKey: '3-0',
        },
      },
      {
        path: 'TestValuation',
        name: 'TestValuation',
        component: () => import('../views/OrderManagement/TestValuation/Index.vue'),
        meta: {
          title: '订单-检测估值',
          dataKey: '3-1',
        },
      },
      {
        path: 'ContractDelivery',
        name: 'ContractDelivery',
        component: () => import('../views/OrderManagement/ContractDelivery/Index.vue'),
        meta: {
          title: '订单-签约交付',
          dataKey: '3-2',
        },
      },
      {
        path: 'RedemptionAtMaturity',
        name: 'RedemptionAtMaturity',
        component: () => import('../views/OrderManagement/RedemptionAtMaturity/Index.vue'),
        meta: {
          title: '订单-期满赎回',
          dataKey: '3-3',
        },
      },
      {
        path: 'Renewal',
        name: 'Renewal',
        component: () => import('../views/OrderManagement/Renewal/Index.vue'),
        meta: {
          title: '订单-续期',
          dataKey: '3-4',
        },
      },
      {
        path: 'GiveUpRealRight',
        name: 'GiveUpRealRight',
        component: () => import('../views/OrderManagement/GiveUpRealRight/Index.vue'),
        meta: {
          title: '订单-放弃物权',
          dataKey: '3-5',
        },
      },
    ],
  },
  {
    path: '/StorageManagement',
    name: 'StorageManagement',
    meta: {
      title: '库存管理',
      dataKey: '4',
      iconUrl: 'iconfont icon-shujuyuan',
    },
    component: index,
    redirect: '/StorageManagement/StorageRecord',
    children: [
      {
        path: 'StorageRecord',
        name: 'StorageRecord',
        component: () => import('../views/Storage/StorageRecord.vue'),
        meta: {
          title: '库存货品列表',
          dataKey: '4-0',
        },
      },
      {
        path: 'WarehouseList',
        name: 'WarehouseList',
        component: () => import('../views/Storage/WarehouseList.vue'),
        meta: {
          title: '货仓列表',
          dataKey: '4-1',
        },
      },
    ],
  },
  {
    path: '/UserManagement',
    name: 'UserManagement',
    meta: {
      title: '用户管理',
      dataKey: '5',
      iconUrl: 'iconfont icon-nikehuguanli',
    },
    component: index,
    redirect: '/UserManagement/UserList',
    children: [
      {
        path: 'UserList',
        name: 'UserList',
        component: () => import('../views/UserManagement/UserList.vue'),
        meta: {
          title: '用户列表',
          dataKey: '5-0',
        },
      },
      {
        path: 'UserApplList',
        name: 'UserApplList',
        component: () => import('../views/UserManagement/UserApplList.vue'),
        meta: {
          title: '用户申请列表',
          dataKey: '5-1',
        },
      },
    ],
  },
  {
    path: '/ChannelManagement',
    name: 'ChannelManagement',
    meta: {
      title: '渠道管理',
      dataKey: '6',
      iconUrl: 'iconfont icon-nikehuguanli',
    },
    component: index,
    redirect: '/ChannelManagement/ChannelUserList',
    children: [
      {
        path: 'ChannelUserList',
        name: 'ChannelUserList',
        component: () => import('../views/ChannelManagement/ChannelUserList/ChannelUserList.vue'),
        meta: {
          title: '渠道用户申请列表',
          dataKey: '6-0',
        },
      },
      {
        path: 'KanongUserList',
        name: 'KanongUserList',
        component: () => import('../views/ChannelManagement/ChannelUserList/KanongUserList.vue'),
        meta: {
          title: '卡农渠道申请列表',
          dataKey: '6-1',
        },
      },
    ],
  },
  {
    path: '/PartnerManagement',
    name: 'PartnerManagement',
    meta: {
      title: '合作商订单管理',
      dataKey: '7',
      iconUrl: 'iconfont icon-nikehuguanli',
    },
    component: index,
    redirect: '/PartnerManagement/PartnerOrderList',
    children: [
      {
        path: 'PartnerOrderList',
        name: 'PartnerOrderList',
        component: () => import('../views/PartnerManagement/PartnerOrderList.vue'),
        meta: {
          title: '订单列表',
          dataKey: '7-0',
        },
      },
    ],
  },
  {
    path: '/Developing',
    name: 'Developing',
    meta: {
      title: 'Developing',
      dataKey: '6',
    },
    component: index,
    redirect: '/Developing/Index',
    children: [
      {
        path: 'Index',
        name: 'Index',
        component: () => import('../views/Developing/Index.vue'),
        meta: {
          title: '开发中',
          dataKey: '6-0',
        },
      },
    ],
  },
];
