import { provinceAndCityData } from 'element-china-area-data';
import store from '@/store';
import { Message } from 'element-ui';

// 身份证号验证
const idCardVerify = (rule, value, callback) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  // const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  if (!reg.test(value)) {
    callback(new Error('请输入正确的身份证号'));
  } else {
    callback();
  }
};
// 手机号验证
const phoneVerify = (rule, value, callback) => {
  const reg = /^1[3456789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};
// 邮箱地址验证
const emailVerify = (rule, value, callback) => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
  if (!reg.test(value)) {
    callback(new Error('请输入正确的邮箱地址'));
  } else {
    callback();
  }
};
// 百分比验证
const scaleVerify = (rule, value, callback) => {
  // eslint-disable-next-line no-useless-escape
  const reg = 100;
  if (!value) {
    callback(new Error('请输入百分比'));
  } else if (Number(value) > reg) {
    callback(new Error('百分比不能超过100%'));
  } else {
    callback();
  }
};

// 表单清空
const clearFields = data => {
  if (Object.prototype.toString.call(data) === '[object Object]') {
    Object.keys(data).forEach(key => {
      if (Object.prototype.toString.call(data[key]) === '[object String]') {
        data[key] = '';
      } else if (Object.prototype.toString.call(data[key]) === '[object Number]') {
        data[key] = '';
      } else if (Object.prototype.toString.call(data[key]) === '[object Boolean]') {
        data[key] = false;
      } else if (Object.prototype.toString.call(data[key]) === '[object Array]') {
        data[key] = [];
      } else if (Object.prototype.toString.call(data[key]) === '[object Object]') {
        data[key] = {};
      }
    });
  } else {
    Message.error('清空表单字段必须为对象类型');
  }
};
// 查询字典表
const filterDictionary = code => {
  const dict = store.getters['login/dictionary'];
  const [res] = dict.filter(val => val.head.value === code);
  return res.data;
};
// 字典表转文字
const valueToName = (list, code) => {
  let name = code;
  list.forEach(val => {
    if (`${val.value}` === `${code}`) {
      name = val.name;
    }
  });
  return name;
};

// 格式化权限菜单数据
const formaterTableData = data => {
  data.forEach(val => {
    val.checked = val.checked.includes('true');
    if (val.checked) this.checkedKeysId.push(val.id);
  });
  const parentMenu = data.filter(val => `${val.pid}` === `${val.id}`);
  const childrenMenu = data.filter(val => `${val.pid}` !== `${val.id}`);
  // 二级菜单
  const childrenArr2 = childrenMenu.filter(val =>
    parentMenu.some(cval => `${val.pid}` === `${cval.id}`));
  // 三级菜单
  const childrenArr3 = childrenMenu.filter(val =>
    childrenArr2.some(cval => `${val.pid}` === `${cval.id}`));
  // 向一级菜单插入子菜单
  parentMenu.forEach(val => {
    val.children = [];
    childrenArr2.forEach(cval => {
      if (`${val.id}` === `${cval.pid}`) {
        val.children.push(cval);
      }
      cval.children = [];
      childrenArr3.forEach(ccval => {
        if (`${cval.id}` === `${ccval.pid}`) {
          cval.children.push(ccval);
        }
      });
    });
  });
  return parentMenu;
};

/**
  @description 错误弹出层提示 confirm
  @param { event } 对象原型
  @param { prev } 开头描述文字
  @param { mid } 高亮提示文字
  @param { next } 尾部描述文字
*/
const errorBox = (event, prev, mid, next) => {
  const h = event.$createElement;
  event
    .$msgbox({
      title: '提示',
      message: h('div', { style: 'display: flex; align-items: center' }, [
        h(
          'i',
          {
            class: 'el-icon-error',
            style: 'color: #F56C6C; font-size: 30px; margin-right: 10px',
          },
          '',
        ),
        h('span', null, prev),
        h('span', { style: 'color: #F56C6C' }, mid),
        h('span', null, next),
      ]),
      showCancelButton: false,
      showConfirmButton: true,
      closeOnPressEscape: false,
      closeOnClickModal: false,
      confirmButtonText: '确定',
      center: false,
    })
    .then(() => {});
};

/*
 *逻辑处理工具类
 */
export default {
  /**
   *
   * @param {*} code 判断按钮是否有权限的code值
   * @description 返回true/false
   */
  getButtonListRules(code) {
    return store.getters['login/buttonList'].some(i => `${i.code}` === `${code}`);
  },
  /**
   *
   * @param {Array} data 后台给的数据字典格式
   * @description 格式化后台返回的数据字典格式，方便取值
   */
  formatDictionary(data) {
    const result = {};
    data.forEach(element => {
      const sunData = {};
      element.datas.forEach(item => {
        sunData[item.value] = item.name;
      });
      result[element.category.value] = {
        name: element.category.name,
        data: sunData,
      };
    });
    return result;
  },
  /**
   *
   * @param {*} code 区域id
   * @description 省市区域解析：code转为区域中文名，传入省返回[省名字]，返回市返回[省名字,市名字]
   */
  AreaCode2Name(code) {
    if (!code) return [];
    const result = [];
    provinceAndCityData.forEach(province => {
      if (province.value === code.toString()) {
        result.push(province.label);
      }
      if (province.children) {
        province.children.forEach(city => {
          if (city.value === code.toString()) {
            result.push(province.label, city.label);
          }
        });
      }
    });
    return result;
  },
  // 输入正整数
  validNumber(number) {
    let n = number;
    n = n.replace(/[^\d]/g, '');
    return n;
  },
  // 输入金额
  validMoney(number) {
    let n = number;
    n = n.replace(/^d*(?:.d{0,2})?$/g, '');
    return n;
  },
  // 表单验证
  idCardVerify,
  phoneVerify,
  emailVerify,
  scaleVerify,
  // 清空表单
  clearFields,
  // 字典筛选
  filterDictionary,
  // 字典表转文字
  valueToName,
  // 格式化权限菜单数据
  formaterTableData,
  // 错误confirm
  errorBox,
};

// 正数，金额控制
export function numberCtrol(data, key, decimals, length) {
  const datas = data;
  const d = '\\d';
  const arr = [d];
  for (let i = 1; i < decimals; i += 1) {
    arr.push(d);
  }
  const dd = arr.join('');
  const decimalLength = new RegExp(`^(-)*(\\d+)\\.(${dd}).*$`);
  datas[key] = datas[key].replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
  datas[key] = datas[key].replace(/^\./g, ''); // 验证第一个字符是数字
  datas[key] = datas[key].replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
  datas[key] = datas[key]
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.');
  if (decimals) {
    datas[key] = datas[key].replace(decimalLength, '$1$2.$3'); // 只能输入n个小数
  } else if (datas[key] !== '') {
    // 只能输入正整数
    datas[key] = datas[key].replace(/[^0-9]/g, '');
    // 整数首位不能以0开头
    datas[key] = String(parseFloat(datas[key]));
    // 不能超过指定长度
    if (length && datas[key].length >= length) {
      datas[key] = datas[key].slice(0, length);
    }
  }
}
