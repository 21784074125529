// import ApiApp from '@/api/app';
// import utils from '@/utils/utils';
import dictionary from '@/utils/dictionary';

const state = {
  userName: '先生/女士',
  staffCode: localStorage.getItem('staffCode'),
  userInfo: '',
  roles: [],
  hasKongZhiTai: '', // 是否有控制台权限
  dictionary: [],
  buttonList: [],
};
const getters = {
  hasKongZhiTai: state => state.hasKongZhiTai,
  dictionary: state => state.dictionary,
  buttonList: state => state.buttonList,
  roles: state => state.roles,
};
const mutations = {
  saveName(state, res) {
    localStorage.setItem('loginUser', res.userName);
    localStorage.setItem('staffCode', res.staffCode);
    state.userName = res.userName;
    state.staffCode = res.staffCode;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    state.userName = userInfo.userName;
    localStorage.setItem('userinfo', JSON.stringify(userInfo));
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_BUTTONLIST: (state, buttonList) => {
    state.buttonList = buttonList;
  },
  SET_DICTIONARY: (state, dictionary) => {
    state.dictionary = dictionary;
  },
  setKongZhiTai: (state, hasKongZhiTai) => {
    state.hasKongZhiTai = hasKongZhiTai;
  },
};
const actions = {
  // GetInfo({ commit }, token) {
  //   const params = {
  //     accessToken: token,
  //     param: '14', // 根据产品填写对应id
  //   };
  //   return new Promise((resolve, reject) => {
  //     ApiApp.reqLoginPosSystemPrivileges(params)
  //       .then(res => {
  //         localStorage.setItem('roles', JSON.stringify(res.result.children));
  //         commit('SET_ROLES', res.result.children);
  //         localStorage.setItem('buttonList', JSON.stringify(res.result.buttonList));
  //         commit('SET_BUTTONLIST', res.result.buttonList);
  //         resolve(res.result);
  //       }).catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  GetDictionary({ commit }) {
    // 前端维护的字典表
    commit('SET_DICTIONARY', dictionary.dictionaryList);
    sessionStorage.setItem('dictionary', JSON.stringify(dictionary.dictionaryList));
    // return new Promise((resolve, reject) => {
    //   // 系统id
    //   ApiApp.reqLoginPostDictionaryBySystem({}, `/${2101}`)
    //     .then(res => {
    //       const dictionary = utils.formatDictionary(res.result);
    //       commit('SET_DICTIONARY', dictionary);
    //       localStorage.setItem('dictionary', JSON.stringify(dictionary));
    //       console.log('字典表获取完毕：');
    //       console.log(dictionary);
    //       resolve(res);
    //     }).catch(error => {
    //       reject(error);
    //     });
    // });
  },
  GetStaffInfo({ commit }, data) {
    commit('SET_USERINFO', data);
    localStorage.setItem('roles', JSON.stringify(data.permissionList));
    commit('SET_ROLES', data.permissionList);
    // return new Promise((resolve, reject) => {
    //   ApiApp.reqStaffInfo({}, code)
    //     .then(res => {
    //       commit('saveName', res.result);
    //       commit('SET_USERINFO', res.result);
    //       resolve(res);
    //     }).catch(err => {
    //       reject(err);
    //     });
    // });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
