// eslint-disable-next-line import/no-cycle
import routers from '@/router';

const state = {
  worktab: {
    list: [
      // {
      //   name: 'home',
      //   tabname: '工作台',
      //   path: '/homepage/home',
      //   dataKey: '0',
      // },
    ],
    current: {
      // name: 'home',
      // tabname: '工作台',
      // path: '/homepage/home',
      // dataKey: '0',
    },
  },
  closingPage: '',
};
const getters = {
  worktab: state => state.worktab.list,
};
const mutations = {
  updateList(state, list) {
    state.worktab.list = list;
  },
  worktabRemove(state, p) {
    // 关闭标签
    const ind = state.worktab.list.findIndex(s => s.name === p);
    if (ind > -1) {
      if (p === state.worktab.current.name) {
        // 是当前页，返回上一页
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of state.worktab.list.entries()) {
          if (value.name === p) {
            if (key > 0) {
              // 左移
              const delpage = state.worktab.list.splice(key, 1); // 删掉自己
              state.closingPage = delpage[0].name;
              const latestView = state.worktab.list[key - 1];
              const { path } = latestView;
              routers.push(path);
            } else {
              // 右移
              const latestView = state.worktab.list[key + 1];
              if (latestView) {
                const { path } = latestView;
                routers.push(path);
              } else {
                routers.push('/');
              }
              state.closingPage = state.worktab.list[key].name;
              state.worktab.list.splice(key, 1);
            }
          }
        }
      } else {
        // 清理 keep alive - start
        state.closingPage = state.worktab.list[ind].name;
        // 清理 keep alive - end
        state.worktab.list.splice(ind, 1);
      }
    }
  },
  worktabRoute(state, p) {
    const ind = state.worktab.list.findIndex(s => s.name === p.to.name);
    if (ind > -1) {
      // 标签已存在
      state.worktab.current = state.worktab.list[ind];
    } else if (p.to.name !== 'login' && p.to.name !== 'page404') {
      // 标签不存在，现在新建
      if (p.to.dataKey !== '') {
        state.worktab.list.push(p.to);
        state.worktab.current = p.to;
      }
    }
    if (p.to.name === state.closingPage) {
      state.closingPage = '';
    }
  },
  clearTag(state) {
    state.worktab.list = [];
  },
};
const actions = {
  worktabRemove({ commit }, p) {
    commit('worktabRemove', p);
  },
  worktabRoute({ commit }, p) {
    commit('worktabRoute', p);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
