import { postInfo } from './api';

export default {
  login(data) { // 获取token
    return postInfo('/login/login', data);
  },
  // 产品分类大类列表
  first() {
    return postInfo('/product/category/first');
  },
  // 产品分类品牌列表
  brand(data) {
    return postInfo('/product/category/brand', data);
  },
};
