import axios from 'axios';
// eslint-disable-next-line
import router from '@/router';
import store from '@/store';
import { Message } from 'element-ui';

// api配置
const config = {
  baseURL: process.env.VUE_APP_BASE_API, // 环境变量
  // baseURL: '/api',
};

const xhr = axios.create(config);

// 添加请求拦截器
xhr.interceptors.request.use(
  config => {
    if (
      localStorage.getItem('token') &&
      config.method === 'post' &&
      !sessionStorage.getItem('isH5')
    ) {
      // eslint-disable-next-line no-param-reassign
      config.data = {
        accessToken: localStorage.getItem('token'),
        param: config.data,
      };
    }
    return config;
  },
  error => Promise.reject(error),
);

// 是否可弹出错误信息
let reqStatus = true;
// 添加响应拦截器
xhr.interceptors.response.use(
  response => {
    let res = '';
    res = response.data;
    if (response.data.code === '402') {
      localStorage.clear();
      router.push('/login').catch(err => {
        console.log(err);
      });
      store.commit('navTag/clearTag');
      store.commit('navMenu/navFixedin');
      store.commit('navMenu/updateSelectKey', '');
    }
    return res;
  },
  error => {
    // 对响应错误做点什么
    const { response } = error;
    let responseDate = response;
    console.log(`axios拦截器报错：${error}`);
    if (response) {
      if (`${response.status}` === '402') {
        if (reqStatus) {
          reqStatus = false;
          Message.error('身份已过期，请重新登录');
          localStorage.clear();
          router.push('/login').catch(err => {
            console.log(err);
          });
          store.commit('navTag/clearTag');
          store.commit('navMenu/navFixedin');
          store.commit('navMenu/updateSelectKey', '');
        }
      } else {
        Message.error('网络出错啦，请检查网络状态');
      }
    } else {
      responseDate = {
        message: '网络出错啦，请检查网络',
      };
    }
    return Promise.reject(responseDate);
  },
);

export default xhr;
