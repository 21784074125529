const state = {
  collapse: true,
  fixed: true,
  selectKey: '',
  user: '先生/女士',
  list: [
    // {
    //   key: '2',
    //   title: '二手回收',
    //   icon: 'iconfont icon-huishou',
    //   children: [
    //     {
    //       key: '2-0',
    //       title: '估值项目配置',
    //       to: '/TwoRecycling/ValuationItem',
    //     },
    //     {
    //       key: '2-1',
    //       title: '产品列表',
    //       to: '/TwoRecycling/ProManage',
    //     },
    //     {
    //       key: '2-2',
    //       title: '产品分类',
    //       to: '/TwoRecycling/ProClassify',
    //     },
    //     {
    //       key: '2-3',
    //       title: '产品维护历史',
    //       to: '/TwoRecycling/ProHistory',
    //     },
    //     {
    //       key: '2-4',
    //       title: '估值参数配置',
    //       to: '/TwoRecycling/ValuationAttr',
    //     },
    //     {
    //       key: '2-5',
    //       title: '续期仓管费用配置',
    //       to: '/TwoRecycling/RenewalFee',
    //     },
    //   ],
    // },
    // {
    //   key: '3',
    //   title: '订单管理',
    //   icon: 'iconfont icon-reportManage',
    //   children: [
    //     {
    //       key: '3-0',
    //       title: '订单列表',
    //       to: '/OrderManagement/OrderList',
    //     },
    //     {
    //       key: '3-1',
    //       title: '订单-检测估值',
    //       to: '/OrderManagement/TestValuation',
    //     },
    //     {
    //       key: '3-2',
    //       title: '订单-签约交付',
    //       to: '/OrderManagement/ContractDelivery',
    //     },
    //     {
    //       key: '3-3',
    //       title: '订单-期满赎回',
    //       to: '/OrderManagement/RedemptionAtMaturity',
    //     },
    //     {
    //       key: '3-4',
    //       title: '订单-续期',
    //       to: '/OrderManagement/Renewal',
    //     },
    //     {
    //       key: '3-5',
    //       title: '订单-放弃物权',
    //       to: '/OrderManagement/GiveUpRealRight',
    //     },
    //   ],
    // },
    // {
    //   key: '4',
    //   title: '库存管理',
    //   icon: 'iconfont icon-shujuyuan',
    //   children: [
    //     {
    //       key: '4-0',
    //       title: '库存货品列表',
    //       to: '/StorageManagement/StorageRecord',
    //     },
    //     {
    //       key: '4-1',
    //       title: '货仓列表',
    //       to: '/StorageManagement/WarehouseList',
    //     },
    //   ],
    // },
    // {
    //   key: '5',
    //   title: '用户管理',
    //   icon: 'iconfont icon-nikehuguanli',
    //   children: [
    //     {
    //       key: '5-0',
    //       title: '用户列表',
    //       to: '/UserManagement/UserList',
    //     },
    //     {
    //       key: '5-1',
    //       title: '用户申请列表',
    //       to: '/UserManagement/UserApplList',
    //     },
    //   ],
    // },
    // {
    //   key: '1',
    //   title: '系统管理',
    //   icon: 'iconfont icon-xitongshezhi',
    //   children: [
    //     {
    //       key: '1-0',
    //       title: '角色管理',
    //       to: '/SystemManage/RoleManage',
    //     },
    //     {
    //       key: '1-1',
    //       title: '系统用户管理',
    //       to: '/SystemManage/UserManage',
    //     },
    //     {
    //       key: '1-2',
    //       title: '权限管理',
    //       to: '/SystemManage/AuthManage',
    //     },
    //     {
    //       key: '1-3',
    //       title: '公司收付款账号维护',
    //       to: '/SystemManage/CompanyAccount',
    //     },
    //   ],
    // },
  ],
};
const getters = {
  title(state) {
    const key = state.selectKey;
    if (!key) {
      return '';
    }
    let t = '';
    if (key.includes('-')) {
      const arr = key.split('-');
      const i = Number.parseInt(arr[0], 10);
      const j = Number.parseInt(arr[1], 10);
      const k = Number.parseInt(arr[2], 10);
      const index = state.list.findIndex(val => `${val.key}` === `${i}`);
      const hasKey = state.list.some(val => `${val.key}` === `${i}`);
      if (hasKey) {
        if (state.list[index].children[j].children) {
          t = state.list[index].children[j].children[k].title;
        } else {
          t = state.list[index].children[j].title;
        }
      } else {
        t = '';
      }
    } else {
      const i = Number.parseInt(key, 10);
      const index = state.list.findIndex(val => `${val.key}` === `${i}`);
      const hasKey = state.list.some(val => `${val.key}` === `${i}`);
      if (hasKey) {
        t = state.list[index].title;
      } else {
        t = '';
      }
    }
    return t;
  },
};
const mutations = {
  toggleCollapse(state, status) {
    state.collapse = status;
  },
  updateSelectKey(state, payload) {
    state.selectKey = payload;
  },
  toggleFixed(state) {
    state.fixed = !state.fixed;
  },
  navFixed(state) {
    state.collapse = false;
  },
  navFixedin(state) {
    state.collapse = true;
    state.selectKey = '0';
  },
  SET_MENU(state, list) {
    state.list = list;
  },
};
const actions = {
  updateMenu({ commit }, routers) {
    return new Promise(resolve => {
      const menu = [];
      // 根据routers生成menus
      routers.forEach((element, index) => {
        const fatherPath = element.path;
        const childMenus = [];
        let toPath = '';
        if (element.meta.title === '首页') {
          toPath = '/homepage/home';
        } else {
          element.children.forEach((child, chindIndex) => {
            childMenus.push({
              key: `${index}-${chindIndex}`,
              title: child.meta.title,
              to: `${fatherPath}/${child.path}`,
            });
          });
        }
        menu.push({
          key: `${index}`,
          title: element.meta.title,
          icon: element.meta.iconUrl,
          to: toPath || '',
          children: childMenus || [],
        });
      });
      commit('SET_MENU', menu);
      resolve(menu);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
