/**
 * @description前端维护的字典表
 */
export default {
  dictionaryList: [
    {
      head: {
        name: '角色类型',
        value: '0101',
      },
      data: [
        {
          name: '岗位',
          value: '1',
        },
        {
          name: '部门',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '角色状态',
        value: '0102',
      },
      data: [
        {
          name: '停用',
          value: '0',
        },
        {
          name: '启用',
          value: '1',
        },
      ],
    },
    {
      head: {
        name: '角色',
        value: '0103',
      },
      data: [
        {
          name: '系统管理员',
          value: '1',
        },
        {
          name: '品牌活动',
          value: '2',
        },
        {
          name: '线上推广',
          value: '3',
        },
        {
          name: '客服TC',
          value: '4',
        },
        {
          name: '数据分析',
          value: '5',
        },
        {
          name: '开发测试岗',
          value: '6',
        },
      ],
    },
    {
      head: {
        name: '权限状态',
        value: '0104',
      },
      data: [
        {
          name: '停用',
          value: '0',
        },
        {
          name: '启用',
          value: '1',
        },
      ],
    },
    {
      head: {
        name: '权限类型',
        value: '0105',
      },
      data: [
        {
          name: '菜单权限',
          value: '1',
        },
        {
          name: '页面跳转权限',
          value: '2',
        },
        {
          name: '页面区域权限',
          value: '3',
        },
      ],
    },
    {
      head: {
        name: '权限等级',
        value: '0106',
      },
      data: [
        {
          name: '一',
          value: '1',
        },
        {
          name: '二',
          value: '2',
        },
        {
          name: '三',
          value: '3',
        },
        {
          name: '四',
          value: '4',
        },
      ],
    },
    {
      head: {
        name: '订单类型',
        value: '0107',
      },
      data: [
        {
          name: '检测估值',
          value: 1,
        },
        {
          name: '签约交付',
          value: 2,
        },
        {
          name: '期满赎回',
          value: 3,
        },
        {
          name: '续期',
          value: 4,
        },
        {
          name: '放弃物权',
          value: 5,
        },
      ],
    },
    {
      head: {
        name: '订单状态',
        value: '0108',
      },
      data: [
        {
          name: '待收货',
          value: 101,
        },
        {
          name: '已收货待检测',
          value: 102,
        },
        {
          name: '检测中',
          value: 103,
        },
        {
          name: '已检测待确认估值',
          value: 104,
        },
        {
          name: '确认估值中',
          value: 105,
        },
        {
          name: '已确认估值待签约',
          value: 106,
        },
        {
          name: '签约中',
          value: 107,
        },
        {
          name: '已取消质押待回寄',
          value: 108,
        },
        {
          name: '已回寄待确认（取消质押）',
          value: 109,
        },
        {
          name: '已确认回寄收货（取消质押）',
          value: 110,
        },
        {
          name: '已签约待支付',
          value: 201,
        },
        {
          name: '已支付',
          value: 202,
        },
        {
          name: '寄存期满待赎回确认',
          value: 301,
        },
        {
          name: '赎回确认中',
          value: 302,
        },
        {
          name: '已赎回待回寄',
          value: 303,
        },
        {
          name: '已回寄待确认到货（赎回）',
          value: 304,
        },
        {
          name: '已确认到货（赎回）',
          value: 305,
        },
        {
          name: '确认延期待签约付定金',
          value: 401,
        },
        {
          name: '签约付定金中',
          value: 402,
        },
        {
          name: '已延期生效',
          value: 403,
        },
        {
          name: '放弃赎回待签约',
          value: 501,
        },
        {
          name: '签约中（放弃物权）',
          value: 502,
        },
        {
          name: '已签约放弃物权',
          value: 503,
        },
        {
          name: '未签约放弃物权',
          value: 504,
        },
      ],
    },
    {
      head: {
        name: '产品大类',
        value: '0109',
      },
      data: [
        {
          name: '手机',
          value: '1',
        },
        {
          name: '笔记本电脑',
          value: '2',
        },
        {
          name: '平板电脑',
          value: '3',
        },
      ],
    },
    {
      head: {
        name: '品牌',
        value: '0110',
      },
      data: [
        {
          name: '苹果',
          value: '1',
        },
        {
          name: '华为',
          value: '2',
        },
        {
          name: 'VIVO',
          value: '3',
        },
        {
          name: '三星',
          value: '4',
        },
      ],
    },
    {
      head: {
        name: '机型配置项',
        value: '0111',
      },
      data: [
        {
          name: '机身颜色',
          value: '1',
        },
        {
          name: '内存配置',
          value: '2',
        },
        {
          name: '总容量配置',
          value: '3',
        },
        {
          name: '网络制式',
          value: '4',
        },
        {
          name: '货源渠道',
          value: '5',
        },
        {
          name: '型号',
          value: '6',
        },
        {
          name: '处理器',
          value: '7',
        },
        {
          name: '显卡',
          value: '8',
        },
        {
          name: '成色（黄金）',
          value: '9',
        },
        {
          name: '附件情况（包装盒/保卡/发票）',
          value: '10',
        },
        {
          name: '黄金克重',
          value: '11',
        },
        {
          name: '变焦倍数',
          value: '12',
        },
        {
          name: '变焦头',
          value: '13',
        },
        {
          name: '机芯类型',
          value: '15',
        },
        {
          name: '表径',
          value: '16',
        },
      ],
    },
    {
      head: {
        name: '商品状态',
        value: '0112',
      },
      data: [
        {
          name: '上架',
          value: '1',
        },
        {
          name: '下架',
          value: '0',
        },
      ],
    },
    {
      head: {
        name: '机身颜色',
        value: '0113',
      },
      data: [
        {
          name: '白色',
          value: '1',
        },
        {
          name: '黑色',
          value: '2',
        },
        {
          name: '红色',
          value: '3',
        },
        {
          name: '蓝色',
          value: '4',
        },
        {
          name: '金色',
          value: '5',
        },
      ],
    },
    {
      head: {
        name: '内存配置',
        value: '0114',
      },
      data: [
        {
          name: '8g',
          value: '1',
        },
        {
          name: '16g',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '寄存天数',
        value: '0115',
      },
      data: [
        {
          name: '3天',
          value: 3,
        },
        {
          name: '7天',
          value: 7,
        },
        {
          name: '10天',
          value: 10,
        },
        {
          name: '14天',
          value: 14,
        },
        {
          name: '15天',
          value: 15,
        },
        {
          name: '20天',
          value: 20,
        },
        {
          name: '21天',
          value: 21,
        },
        {
          name: '28天',
          value: 28,
        },
        {
          name: '30天',
          value: 30,
        },
      ],
    },
    {
      head: {
        name: '客户不接受最新报价原因/订单取消原因',
        value: '0116',
      },
      data: [
        {
          name: '觉得平台估值过低',
          value: 1,
        },
        {
          name: '觉得寄存费用过高',
          value: 2,
        },
        {
          name: '觉得手续太麻烦',
          value: 3,
        },
        {
          name: '担心存放不安全',
          value: 4,
        },
        {
          name: '担心平台是否正规',
          value: 5,
        },
        {
          name: '其他原因',
          value: 6,
        },
      ],
    },
    {
      head: {
        name: '支付账号属性',
        value: '0117',
      },
      data: [
        {
          name: '对公',
          value: '1',
        },
        {
          name: '个人',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '支付账号种类',
        value: '0118',
      },
      data: [
        {
          name: '微信支付',
          value: '1',
        },
        {
          name: '支付宝支付',
          value: '2',
        },
        {
          name: '网银支付',
          value: '3',
        },
        {
          name: '其他',
          value: '4',
        },
      ],
    },
    {
      head: {
        name: '客户选择非赎回方式',
        value: '0119',
      },
      data: [
        {
          name: '续期',
          value: '1',
        },
        {
          name: '放弃物权',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '不愿意放弃赎回签约原因',
        value: '0120',
      },
      data: [
        {
          name: '觉得麻烦',
          value: 1,
        },
        {
          name: '失联',
          value: 2,
        },
        {
          name: '恶意拖延',
          value: 3,
        },
        {
          name: '其他',
          value: 4,
        },
      ],
    },
    {
      head: {
        name: '跳转类型',
        value: '0121',
      },
      data: [
        {
          name: '问题跳转',
          value: 1,
        },
        {
          name: '正常结束',
          value: 2,
        },
        {
          name: '提前结束',
          value: 3,
        },
      ],
    },
    {
      head: {
        name: '库存状态',
        value: '0122',
      },
      data: [
        {
          name: '在库中',
          value: '1',
        },
        {
          name: '已出库',
          value: '0',
        },
      ],
    },
    {
      head: {
        name: '是否',
        value: '0123',
      },
      data: [
        {
          name: '是',
          value: 1,
        },
        {
          name: '否',
          value: 0,
        },
      ],
    },
    {
      head: {
        name: '货仓状态',
        value: '0124',
      },
      data: [
        {
          name: '满仓',
          value: '1',
        },
        {
          name: '空仓',
          value: '0',
        },
      ],
    },
    {
      head: {
        name: '问题状态',
        value: '0125',
      },
      data: [
        {
          name: '待发布',
          value: 1,
        },
        {
          name: '已发布',
          value: 2,
        },
        {
          name: '已下线',
          value: 3,
        },
        {
          name: '已作废',
          value: 4,
        },
      ],
    },
    {
      head: {
        name: '账号属性',
        value: '0126',
      },
      data: [
        {
          name: '对公',
          value: '1',
        },
        {
          name: '个人',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '账号种类',
        value: '0127',
      },
      data: [
        {
          name: '微信支付',
          value: '1',
        },
        {
          name: '支付宝支付',
          value: '2',
        },
        {
          name: '网银支付',
          value: '3',
        },
        {
          name: '其他',
          value: '4',
        },
      ],
    },
    {
      head: {
        name: '账号用途',
        value: '0128',
      },
      data: [
        {
          name: '付款',
          value: '1',
        },
        {
          name: '收款',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '入账状态',
        value: '0129',
      },
      data: [
        {
          name: '待入账',
          value: '1',
        },
        {
          name: '已入账',
          value: '2',
        },
      ],
    },
    {
      head: {
        name: '爱押分润比例',
        value: '0130',
      },
      data: [
        {
          name: '30%',
          value: 30,
        },
        {
          name: '35%',
          value: 35,
        },
        {
          name: '40%',
          value: 40,
        },
        {
          name: '50%',
          value: 50,
        },
        {
          name: '100%',
          value: 100,
        },
      ],
    },
  ],
};
