import Vue from 'vue';
import Vuex from 'vuex';

import navMenu from './modules/navMenu';
import navTag from './modules/navTag';
import login from './modules/login';
import permission from './modules/permission';
import app from './modules/app';

import OrderList from './modules/OrderList';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    navMenu,
    navTag,
    login,
    permission,
    app,

    OrderList,
  },
});
