import { authRoutes, routes } from '@/router';
import store from '@/store';

// 校验权限
function hasPermission(route, roles) {
  // route 路由表中的单个权限
  // roles 后台返回用户所有权限
  const res = roles.some(val => val.name === route.meta.title);
  return res;
}

// 生成二级路由
function filterRouter(childrenRoutes, roles) {
  const filterRouters = childrenRoutes.filter(route => hasPermission(route, roles));
  return filterRouters;
}

function creatDataKey(accessedRouters) {
  accessedRouters.forEach((element, index) => {
    // eslint-disable-next-line no-param-reassign
    element.meta.dataKey = `${index}`;
    element.children.forEach((item, citem) => {
      if (item.path === 'home') {
        // eslint-disable-next-line no-param-reassign
        item.meta.dataKey = `${index}`;
      } else {
        // eslint-disable-next-line no-param-reassign
        item.meta.dataKey = `${index}-${citem}`;
      }
    });
  });
  return accessedRouters;
}

// 生成一级路由
function filterAsyncRouter(routes, roles) {
  const accessedRouters = routes.filter((route, index) => {
    let findFirstRule = false; // 一级在权限中是否存在
    // 赋予图标icon
    roles.forEach(item => {
      if (route.meta.title === item.name) {
        // route.meta.iconUrl = `iconfont ${item.iconUrl}`;
        findFirstRule = true;
      }
    });
    // 不是index页面 && 有children返回true    是index页面 && 有权限返回true
    if ((route.meta.title !== '首页' && findFirstRule) || (route.meta.title === '首页' && hasPermission(route, roles))) {
      // 有 控制台 页面
      if (route.meta.title === '首页') {
        store.commit('login/setKongZhiTai', true);
      }
      // 处理二级路由权限
      if (route.meta.title !== '首页' && route.children.length && route.children) {
        const childRoles = roles.filter(item => item.name === route.meta.title);
        // eslint-disable-next-line no-param-reassign
        route.children = filterRouter(route.children, childRoles[0].children, index);
      }
      return true;
    }
    return false;
  });
  // 经过过滤后生成的新的路由权限表
  return accessedRouters;
}

//  排序
// function sortRouter(router) {
//   router.sort((el1, el2) => Number(el1.meta.sort) - Number(el2.meta.sort));
//   return router;
// }

// 获得排序
// function getSortRouter(router) {
//   const newRouter = sortRouter(router);
//   newRouter.forEach(element => {
//     if (element.children) {
//       getSortRouter(element.children);
//     }
//   });
//   return newRouter;
// }

const state = {
  routers: routes,
  addRouters: [],
};

const getters = {
  addRouters: state => state.addRouters,
};

const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.addRouters = routers;
    state.routers = routes.concat(routers);
  },
};

const actions = {
  // 根据roles权限生成可访问的路由表
  GenerateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      // console.log('加载的未经处理的路由表：');
      // console.log(authRoutes);
      // console.log('后端给的菜单权限：');
      // console.log(roles.roles);
      const accessedRouters = filterAsyncRouter(authRoutes, roles.roles);
      // let accessedSortRouters = getSortRouter(accessedRouters);// 排序
      let accessedSortRouters = accessedRouters;
      accessedSortRouters = creatDataKey(accessedSortRouters);// 生成datakey
      // console.log('最终处理后的菜单权限路由表：');
      // console.log('accessedSortRouters', accessedSortRouters);
      commit('SET_ROUTERS', accessedSortRouters);
      resolve(accessedSortRouters);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
