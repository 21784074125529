import {} from '../../api/api';

const initState = {};
const getters = {};
const mutations = {};
const actions = {};

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
};
