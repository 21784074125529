import appApi from '@/api/app';

const state = {
  firstPro: [],
  secondPro: [],
};
const getters = {};
const mutations = {
  getFirstPro(state, paload) {
    state.firstPro = paload;
    sessionStorage.setItem('firstPro', JSON.stringify(paload));
  },
  getSecondPro(state, paload) {
    state.secondPro = paload;
    sessionStorage.setItem('secondPro', JSON.stringify(paload));
  },
  resetSecondPro(state) {
    state.secondPro = [];
  },
  resetFirstPro(state) {
    state.firstPro = [];
  },
};
const actions = {
  // 获取品牌产品
  getSecondPro({ commit }) {
    return new Promise((resolve, reject) => {
      appApi.brand(
        {
          cateLevel: '',
          parentCateId: '',
        },
      )
        .then(res => {
          commit('getSecondPro', res.result);
          resolve(res);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  // 获取大类产品
  getFirstPro({ commit }) {
    return new Promise((resolve, reject) => {
      appApi.first()
        .then(res => {
          commit('getFirstPro', res.result);
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
