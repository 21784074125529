<template>
  <div class="home">
    <el-row>
      <el-button>默认按钮</el-button>
      <el-button type="primary">主要按钮</el-button>
      <el-button type="success">成功按钮</el-button>
      <el-button type="info">信息按钮</el-button>
      <el-button type="warning">警告按钮</el-button>
      <el-button type="danger" @click="$router.push('/login')">登录</el-button>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
};
</script>
