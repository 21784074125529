import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css';
import router, { createRouter, routes } from '@/router';
import store from '@/store';
import { Message } from 'element-ui';

NProgress.inc(0.2);
NProgress.configure({ easing: 'linear', speed: 300, showSpinner: false });

// 路由钩子
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const token = localStorage.getItem('token');
  if (token) {
    if (!Object.keys(store.getters['login/dictionary']).length) {
      await store.dispatch('login/GetDictionary'); // 加载字典表
    }
    if (!store.state.login.userInfo) {
      const userInfo = JSON.parse(localStorage.getItem('userinfo'));
      await store.commit('login/SET_USERINFO', userInfo);
    }
    // if (!store.state.app.firstPro.length) {
    //   await store.dispatch('app/getFirstPro');
    //   await store.dispatch('app/getSecondPro');
    // }
    // if (!store.getters['login/roles'].length) {
    //   await store.dispatch('login/GetInfo', token); // 获取权限信息
    // }
    // 配置动态路由【动态路由配置完毕，则不需要再配置】
    const pass = false;
    if (store.getters['permission/addRouters'].length !== 0 || pass) { // 第一次没有动态路由表  第二次就有了
      if (to.path === '/login') {
        if (store.getters['login/hasKongZhiTai']) {
          next({ path: `/homepage/home?redirect=${Math.random()}` });
        }
        next({ path: `/?redirect=${Math.random()}` });
      } else if (to.path === '/') { // 进入 index 页面，判断有没有首页，有的话跳转到首页
        if (store.getters['login/hasKongZhiTai']) {
          next({ path: `/homepage/home?redirect=${Math.random()}` });
        } else {
          next();
          store.commit('navMenu/updateSelectKey', '');
          store.state.navTag.worktab.current = {};
        }
      } else {
        next();
        if (to.meta.dataKey) {
          store.dispatch('navTag/worktabRoute', {
            to: {
              name: to.name ? to.name : '',
              tabname: (to.meta && to.meta.title) ? to.meta.title : '',
              path: to.path,
              dataKey: (to.meta && to.meta.title) ? to.meta.dataKey : '',
            },
            from: {
              name: from.name ? from.name : '',
              tabname: (from.meta && from.meta.title) ? from.meta.title : '',
              path: from.path,
              dataKey: (from.meta && from.meta.title) ? from.meta.dataKey : '',
            },
          });
          localStorage.setItem('tag_list', JSON.stringify(store.getters['navTag/worktab']));
          store.commit('navMenu/updateSelectKey', to.meta.dataKey);
        } else {
          store.commit('navMenu/updateSelectKey', '');
        }
      }
    } else {
      const tagList = JSON.parse(localStorage.getItem('tag_list'));
      store.commit('navTag/updateList', tagList || []);
      const roles = JSON.parse(localStorage.getItem('roles'));
      if (roles.length === 0) {
        Message.error('该账号没有任何权限，请联系管理员！');
        localStorage.removeItem('token');
        next({
          path: `/login?redirect=${Math.random()}`,
        });
      } else {
        store.dispatch('permission/GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
          router.match = createRouter(routes).match;
          router.addRoutes(store.getters['permission/addRouters']); // 动态添加可访问路由表
          router.addRoutes([{ path: '*', redirect: '/404' }]); // 动态添加404页面，->  防止未加载完动态路由时，访问页面就会出现404
          store.dispatch('navMenu/updateMenu', store.getters['permission/addRouters']);// 根据路由表生成需要的menus菜单
          next({ ...to }); // hack方法 确保addRoutes已完成 , replace: true 导航不会留下历史记录
        });
      }
    }
  } else if (to.path === '/login') {
    next();
  } else if (to.name === '404') {
    next();
  } else {
    next({
      path: `/login?redirect=${Math.random()}`,
    });
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done();
});
