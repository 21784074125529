import xhr from './base';
import { Message } from 'element-ui';

// post请求
export function postInfo(url, data) {
  const promise = new Promise((resolve, reject) => {
    xhr
      .request({
        url,
        method: 'post',
        data,
      })
      .then(async response => {
        if (response.code === '200') {
          const { result } = response;
          if (result && result.datas && result.datas.length) {
            await result.datas.forEach(val => {
              Object.keys(val).forEach(key => {
                if (Object.prototype.toString.call(val[key]) === '[object Number]') {
                  val[key] = `${val[key]}`;
                }
              });
            });
          }
          resolve(response);
        } else {
          Message.error(response.message);
          reject(response);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
  return promise;
}

export function postRequestApiBlob(url, params) {
  const promise = new Promise((resolve, reject) => {
    xhr
      .post(url, params, { responseType: 'blob' })
      .then(res => {
        // 后台规定怎样为请求成功
        resolve(res);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
  return promise;
}

// get请求
export function getInfo(url, data) {
  const promise = new Promise((resolve, reject) => {
    xhr
      .request({
        url,
        method: 'get',
        data,
      })
      .then(response => {
        if (response.code === '200') {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
  return promise;
}
